// SellerHeader.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import './seller-header.css';

import towTruckSmall from '../../img/towTruckSmall.png';
import smallTransparentLogo from '../../img/smallTransparentLogo.png';
import smallPriceTag from '../../img/priceTagSmall.png';
import callIcon from '../../img/phoneSmallWhite.png';
import emailIcon from '../../img/mailSmallWhite.png';
import getYourOfferBW from '../../img/car_dollor_bw_icon.png';
import howItWorksBW from '../../img/towing_bw_icon.png';
import aboutUsBW from '../../img/recycling_bw_icon.png';

const SellerHeader = ({startBoxRef, stepsContainerRef, setShowHowItWorks, selectedLanguage, setSelectedLanguage, clientPhoneNumber }) => {
    const { t, i18n } = useTranslation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    // React router hooks
    const navigate = useNavigate();
    const location = useLocation();

    // Scroll functions
    const scrollToStartBox = () => {
        const elementPosition = startBoxRef.current.getBoundingClientRect().top + window.pageYOffset;
        const headerHeight = window.innerHeight * 0.07; // 6vh
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const scrollToStepsContainer = () => {
        const elementPosition = stepsContainerRef.current.getBoundingClientRect().top + window.pageYOffset;
        const headerHeight = window.innerHeight * 0.08; // 6vh
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });    };

    // Sidebar functions
    const toggleHowItWorks = () => {
        if (location.pathname !== '/') {
            navigate('/sell/calculate-offer');
        }
        closeSidebar();
        setTimeout(setShowHowItWorks(true), 300);
    };

    // const toggleAboutUs = () => {
    //     closeSidebar();
    //     navigate('/sell/about-us')
    // };

    const toggleGetAnOffer = () => {
        if (location.pathname !== '/') {
            navigate('/sell/calculate-offer');
        }
        closeSidebar();
        setTimeout(scrollToStartBox, 300);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };


    useEffect(() => {
        // Event listener for clicks outside the sidebar
        const handleOutsideClick = (event) => {
            const sidebar = document.querySelector('.sidebar');
            const hamburgerMenu = document.querySelector('.hamburger-menu'); // Replace with your hamburger menu selector

            // Check if the click is outside the sidebar and not on the hamburger menu
            if (sidebar && !sidebar.contains(event.target) &&
                (!hamburgerMenu || !hamburgerMenu.contains(event.target))) {
                closeSidebar();
            }
        };

        // Adding event listener when the sidebar is open
        if (isSidebarOpen) {
            document.addEventListener('click', handleOutsideClick);
        }

        // Cleanup: Remove event listener when the component unmounts or sidebar closes
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isSidebarOpen]); // Dependency array ensures this runs only when isSidebarOpen changes


    // SellerHeader ease-in-out logic
    let lastScrollTop = 0;
    const header = document.querySelector('.header-band');
    const hideThreshold = 75; // Threshold in pixels to hide the header
    const showThreshold = 0; // Threshold in pixels to show the header (or you can use a small value)

    if (header) {
        const headerHeight = header.offsetHeight;

        window.addEventListener("scroll", function() {
            var currentScroll = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScroll > lastScrollTop) {
                // Scrolling Down
                if (currentScroll > lastScrollTop + hideThreshold) {
                    // Make sure sidebar isnt open
                    header.style.transitionDuration = '0.5s'; // Slower for easing out
                    header.classList.add('hide-header');
                    closeSidebar();
                }
            } else {
                // Scrolling Up
                header.style.transitionDuration = '0.2s'; // Faster for easing in
                header.classList.remove('hide-header');
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        }, false);
    }

    // Language switch function
    function LanguageSwitcher() {
        const { i18n } = useTranslation();

        const switchLanguage = (language) => {
            i18n.changeLanguage(language);
            setSelectedLanguage(language);
        };

        return (
            <div className="language-switcher">
                <button
                    className={`language-switch-button ${selectedLanguage === 'fr' ? 'selected' : ''}`}
                    onClick={() => switchLanguage('fr')}
                >
                    FR
                </button>
                <button
                    className={`language-switch-button ${selectedLanguage === 'en' ? 'selected' : ''}`}
                    onClick={() => switchLanguage('en')}
                >
                    EN
                </button>
            </div>
        );
    };

    return (
        <div className="header-band">
            <div className="company-name">
                <h1 className="h1-header">{t('header.companyName')}</h1>
            </div>
            <LanguageSwitcher />
            <div className="widescreen-nav-bar">
                <button onClick={toggleGetAnOffer} className="header-button" aria-label="Get an offer for your car">
                    <div className="bw-header-icon">
                        <img src={getYourOfferBW} alt="car-price" />
                    </div>
                    <div className="header-button-text">{t('sidebar.getanoffer')}</div>
                </button>
                <button onClick={toggleHowItWorks} className="header-button" aria-label="Learn how it works">
                    <div className="bw-header-icon">
                        <img src={howItWorksBW} alt="towing"/>
                    </div>
                    <div className="header-button-text">{t('sidebar.howitworks')}</div>
                </button>
                {/* <button onClick={toggleAboutUs} className="header-button" aria-label="Learn more about us">
                    <div className="bw-header-icon">
                        <img src={aboutUsBW} alt="recycling-leaf" />
                    </div>
                    <div className="header-button-text">{t('sidebar.aboutus')}</div>
                </button> */}
            </div>

            <div className={`hamburger-menu ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                <div></div> {/* Line 1 */}
                <div></div> {/* Line 2 */}
                <div></div> {/* Line 3 */}
            </div>

            <div className={`sidebar ${isSidebarOpen ? 'visible' : ''}`}>

                <nav className="sidebar-nav">
                    <button onClick={toggleGetAnOffer} className="sidebar-button get-offer" aria-label="Get an offer for your car">
                        <img src={smallPriceTag} alt="" className="button-icon" />
                        {t('sidebar.getanoffer')}
                    </button>
                    <button onClick={toggleHowItWorks} className="sidebar-button" aria-label="Learn how it works">
                        <img src={towTruckSmall} alt="" className="button-icon" />
                        {t('sidebar.howitworks')}
                    </button>
                    {/* <button onClick={toggleAboutUs} className="sidebar-button" aria-label="Learn more about us">
                        <img src={smallTransparentLogo} alt="" className="button-icon" />
                        {t('sidebar.aboutus')}
                    </button> */}
                </nav>


                <div className="sidebar-header">
                    <h1>{t('header.companyName')}</h1>
                </div>

                <button className="sidebar-button" aria-label="Call us">
                    <a href={`tel:${clientPhoneNumber || '514-648-1010'}`} className="contact-button" aria-label="Call us">
                        <img src={callIcon} alt="Call Icon" className="contact-button-icon" />
                        {clientPhoneNumber || '514-648-1010'}
                    </a>
                </button>
                <button className="sidebar-button" aria-label="Email us">
                    <a href="mailto:support@ecorecyclageqc.com" className="contact-button" aria-label="Email us">
                        <img src={emailIcon} alt="" className="contact-button-icon" />
                        support@ecorecyclageqc.com
                    </a>
                </button>
            </div>

        </div>
    );
};

export default SellerHeader;